import React from "react";
import { withStyles } from "@mui/styles";
import { TextField } from "@mui/material";

const CssTextField = withStyles({
  root: {
    "& .MuiInputBase-input": {
      textTransform: "lowercase",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#FFFFFF",
      },
      "&:hover fieldset": {
        borderColor: "#FFFFFF",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#FFFFFF",
      },
    },
  },
})(TextField);

function OtpInput({ fieldId, inputRef, index, inputValues, setInputValues }) {
  const handleChange = (e) => {
    if (e.target.value !== "" && e.code !== "Backspace") {
      const element = document.getElementById(`otp-${index + 1}`);
      if (element) {
        element.focus();
        element.select();
      }
    }

    const tempValues = { ...inputValues };

    if (e.target.value === "") {
      e.target.value = "";
      let updatedIndex = index - 1;
      if (updatedIndex === 0) {
        updatedIndex = 1;
      }
      const previousInput = document.getElementById(`otp-${updatedIndex}`);
      previousInput.focus();
      e.target.blur();
      tempValues[fieldId] = "";
    }
    tempValues[fieldId] = e.target.value;
    setInputValues(tempValues);
  };

  return (
    <CssTextField
      inputRef={inputRef}
      id={fieldId}
      name={index.toString()}
      inputProps={{
        maxLength: 1,
        style: {
          padding: 0,
          textAlign: "center",
          height: 32,
          width: 32,
          borderRadius: "none",
        },
      }}
      value={inputValues[fieldId]}
      onChange={handleChange}
      onKeyUp={(e) => {
        if (inputValues[fieldId] === "" && e.code === "Backspace") {
          const element = document.getElementById(`otp-6`);
          if (element) {
            element.focus();
            element.select();
          }
        }
      }}
      onPaste={(e) => {
        e.preventDefault(); // Prevent the default paste behavior

        const clipboardText = e.clipboardData.getData("text/plain");
        let inputValues = {};

        for (let i = 0; i < 6; i++) {
          inputValues[`otp-${i + 1}`] =
            i < clipboardText.length ? clipboardText.charAt(i) : "";
        }

        setInputValues({ ...inputValues });

        const element = document.getElementById("otp-6");

        if (element !== null) {
          element.focus();
        }
      }}
    />
  );
}

export default OtpInput;
