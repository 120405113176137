export class LocalStorageContants {
  static auth = {
    hashKey: "hash_key",
    badInfo: "badInfo",
    badUid: "bad_uuid",
    contactUid: "contact_uid",
    ipAddress: "ip_address",
    accessToken: "access_token",
    last_login: "last-login",
    crossSiteToken: "cross_site_token",
  };
}
