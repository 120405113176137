import { useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { UrlHelper } from "src/helpers/helpersUtil";

const LogOut = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const attachedData = queryParams.get('data') ?? undefined;

    useEffect(() => {
        localStorage.removeItem('access_token')
        localStorage.removeItem('domain')
        if (attachedData) {
          const parsedData = JSON.parse(decodeURIComponent(attachedData));
          parsedData.signupltd && localStorage.setItem('domain', UrlHelper.getTiccaLtdDomain());
          parsedData.easyLoginHashKey && localStorage.setItem('login_hash_key', parsedData.easyLoginHashKey);
          switch (parsedData.logout) {
            case 'ltd' :
              window.location.replace(UrlHelper.getWWWPage())
              break;
          }
        }
        navigate('/')
    }, []);

    return (
        <div>
        </div>
    )
}

export default LogOut