import {
  ILoginRequestPayload,
  ILogOutSubject,
  IRememberSubject,
  IResendMobileSubject,
  IVerificationRespose,
} from "../../@type/api/IAuthSerivices";
import axios from "axios";
import { axiosAuthInstance, axiosBaseInstance, AxiosControlInstance, axiosKaosInstance } from "../axios";
import { jsonToForm } from "../helpersUtil";
import { AxiosResponse } from "axios";
import { IApiResponse } from "../../@type/api/IApis";

class AuthApiServices {
  static login = async (
    values: ILoginRequestPayload
  ): Promise<AxiosResponse<IApiResponse<any>>> => {
    const formValues = jsonToForm(values);
    return await axiosAuthInstance.post("/login",formValues,
      {
        headers: {
          "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary2042MHXUt7ofBGDs"
        }
      });
  };

  static mobileVerification = async (
    values: IVerificationRespose
  ): Promise<AxiosResponse<IApiResponse<any>>> => {
    const formValues = jsonToForm(values);
    return await axiosAuthInstance.post("/verify/login", formValues);
  };

  static newDeviceMobileVerification = async (
    values: IVerificationRespose
  ): Promise<AxiosResponse<IApiResponse<any>>> => {
    const formValues = jsonToForm(values);
    return await axiosBaseInstance.post("/register/verify/phone", formValues);
  };

  static resendMobileVerification = async (
    values: IResendMobileSubject
  ): Promise<AxiosResponse<IApiResponse<any>>> => {
    const formValues = jsonToForm(values);
    return await AxiosControlInstance.post("resend/login/keyword", formValues);
  };

  static resendNewDeviceMobileVerification = async (
    values: IResendMobileSubject
  ): Promise<AxiosResponse<IApiResponse<any>>> => {
    const formValues = jsonToForm(values);
    return await axiosBaseInstance.post("/register/resend/signin/sms", formValues);
  };
  
  static remember = async (
    values: IRememberSubject
  ): Promise<AxiosResponse<IApiResponse<any>>> => {
    const formValues = jsonToForm(values);
    return await axiosKaosInstance.post("/add/remember", formValues);
  };

  static getSSO = async (
    values: string
  ): Promise<AxiosResponse<IApiResponse<any>>> => {
    const formData = new FormData();
    formData.append("domain", values);

    return await axiosBaseInstance.post("/auth/sso/login", formData);
  };

  static validateAccessToken = async (
    values: string
  ): Promise<AxiosResponse<IApiResponse<any>>> => {
    const formData = new FormData();
    formData.append("access_token", values);

    return await axiosAuthInstance.post("/validate-token", formData);
  };

  static getDomain = async (
    // values: string
  ): Promise<AxiosResponse<IApiResponse<any>>> => {
    // const formData = new FormData();
    // formData.append("domain", values);

    return await axiosBaseInstance.get("/auth/sso/getdomain");
  };

}
export default AuthApiServices;
