import React, { createContext, useContext, useState } from 'react';
import { BrowserRouter as Router, Route, Navigate, Routes, RouteProps, Outlet } from 'react-router-dom';
import { PathRouteProps, LayoutRouteProps, IndexRouteProps } from 'react-router-dom';


// Define the type for the authentication context
interface AuthContextType {
  isLoggedIn: boolean;
  login: () => void;
}

interface RouterProps {
  element: JSX.Element;
  path : string;
}

// Create the authentication context
export const AuthContext = createContext<AuthContextType | undefined>(undefined);

// AuthProvider component to manage authentication state
export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const login = () => {
    setIsLoggedIn(true);
  };

  const logout = () => {
    // Perform logout logic (e.g., clear authentication token)
    setIsLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login }}>
      {children}
    </AuthContext.Provider>
  );
}

// useAuth hook to access authentication context
export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export const PrivateRoute = ()  => {
  const { isLoggedIn } = useAuth();
  // console.log("isLoggedIn", isLoggedIn)

  return (
    isLoggedIn ? <Outlet /> : <Navigate to="/" />
  );
}