import { toast } from "react-toastify";

export class Toast {
  static toastConfig: any = {
    autoClose: 3000,
    closeOnClick: true,
    position: "bottom-right",
    hideProgressBar: true,
    className: "toast-container",
  };

  static success = (message: string): any => {
    const successConfig = { ...this.toastConfig, className: "toast-container success-message" };
    return toast.success(message, successConfig);
  };

  static error = (message: string): any => {
    const errorConfig = { ...this.toastConfig, className: "toast-container error-message" };
    return toast.error(message, errorConfig);
  };

  static warning = (message: string): any => {
    const warningConfig = { ...this.toastConfig, className: "toast-container warning-message" };

    return toast.warning(message, warningConfig);
  };
}
