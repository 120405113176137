import axios from "axios";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

export const getIpAddress = async () => {
  try {
    const baseURL = "https://ip.ticca.com/";

    const res = await axios.get(baseURL);

    return res.data ?? "";
  } catch (err) {
    return "";
    // debugger;
  }
};


