import { lazy, Suspense, useEffect } from "react";
import {Route, Routes, BrowserRouter} from "react-router-dom";
import { clientRoutes } from "./utils/routes";
import { ToastContainer } from "react-toastify";
import MobileVerification from "./login/MobileVerification";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "./styles/colors.scss";
import "./styles/classes.scss";
import "./styles/animations.scss";
import "./styles/styles.scss";
import {PrivateRoute, AuthProvider} from "./Verification";
import ErrorBoundary from "./ErrorBoundary";
import LogOut from "./login/Logout";
import NewDeviceVerification from "./login/NewDeviceLogin";
const Login = lazy(() => import("./login/Login"));

const App = () => {

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', "dark");
  }, []);

  return (
    <AuthProvider >

    <BrowserRouter data-theme="dark">
      <Suspense fallback={<div></div>}>
      <ErrorBoundary>
        <Routes>
          <Route element={<PrivateRoute/>}>
            {/* <Route path={clientRoutes.remember} element={<RememberDevice/> }/> */}
            <Route
            path={clientRoutes.mobileVerification}
            element={<MobileVerification />}
          />
          </Route>
          <Route path={clientRoutes.login} element={<Login />} />
          <Route path={clientRoutes.logout} element={<LogOut />} />
          <Route path={clientRoutes.newDeviceMobileVerification} element={<NewDeviceVerification />} />
        </Routes>
        </ErrorBoundary>
      </Suspense>
      {/* <Footer /> */}
      {/* <Layout /> */}
      <ToastContainer />

      {/* <iframe src="http://" frameborder="0" style={{ display: "none" }}></iframe> */}
    </BrowserRouter>
    </AuthProvider>
  );
};

export default App;
