import React, { useEffect, useState } from "react";
import {
  TiccaLogoIconWhite,
  TiccaLogoTextWhite,
  ColorPalleteIcon,
  VersionStacksIcon,
} from "../components/icons";
import { Link, useNavigate } from "react-router-dom";
import "./MobileVerification.scss";
import { clientRoutes } from "../utils/routes";
import OtpStack from "./OtpStack";
import moment from "moment";
import { getIpAddress } from "../helpers/helpers.module";
import { AuthLocalStorageServices } from "../services/localStorage/authLocalStorageService";
import { Toast } from "../utils/toast";
import { CircularProgress } from "@mui/material";
import AuthApiServices from "../helpers/api/authApiServices";
import { toast } from "react-toastify";
import { GetCrossSiteAccessToken } from "../utils/functions";

export const getExpiryTime = () => {
  const expiry = localStorage.getItem('expiry');
  return new Date(expiry * 1000) - new Date();
};

const initialOtpValues = {
  "otp-1": "",
  "otp-2": "",
  "otp-3": "",
  "otp-4": "",
  "otp-5": "",
  "otp-6": "",
};

const MobileVerification = () => {
  const navigate = useNavigate();
  const hasRun = React.useRef(false);

  const [inputValues, setInputValues] = useState(initialOtpValues);
  const [duration, setDuration] = useState(moment.duration(5, "minutes"));
  const [state, setState] = useState({
    longitude: "",
    latitude: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const phone = localStorage.getItem("phone_number");
  const setCoordinates = async () => {
    if (navigator.geolocation) {
      setState({ ...state, isLoading: true });
      navigator.geolocation.getCurrentPosition(
        (position) => {
          let lat = position.coords.latitude.toString() ?? "0.0";
          let long = position.coords.longitude.toString() ?? "0.0";

          setState({
            ...state,
            longitude: long,
            latitude: lat,
            isLoading: false,
          });
        },
        (err) => {
          console.log("We can't access your current geolocation!", err);
          Toast.warning(
            "We can't access your current geolocation, please enable your location services for best experience with us if your device supports the location services."
          );
          setState({ ...state, isLoading: false });
        }
      );
    } else {
      setState({ ...state, latitude: "360", longitude: "360" });
    }
  };

  useEffect(() => {
    if (!hasRun.current) {
      setCoordinates().then(r => r);
      hasRun.current = true;
    }
  }, []);

  const handleSubmitOTP = async () => {
    const keys = Object.keys(inputValues);
    const otpValue = keys.map((el) => inputValues[el]).join("");

    console.log("OTP value", otpValue);
    setError("");

    if (otpValue.length !== 6 || !otpValue) {
      setError({
        message: "Please enter valid verification code!",
      });
      return;
    }

    const checkLocation = (value) => {
      if (value && value !== "") {
        return value;
      }
      return "0.0";
    };

    const values = {
      keyword: otpValue,
      ip_address: await getIpAddress(),
      hash_key: localStorage.getItem("hash_key"),
      session_key: localStorage.getItem("session_key"),
      longitude: checkLocation(state.longitude),
      latitude: checkLocation(state.latitude),
    };

    setLoading(true);
    try {
      // debugger;
      const res = await AuthApiServices.mobileVerification(values);

      if (res?.data?.status === 1) {
        AuthLocalStorageServices.tokens.setToken(res.data);
        GetCrossSiteAccessToken(localStorage.getItem("domain"), AuthLocalStorageServices.tokens.getAccessToken(), navigate);
    }

      // localStorage.setItem();
      if (
        res?.data?.["error_code"] &&
        (res?.data?.["error_code"] === 3 ||
          res?.data?.["error_code"] === 4 ||
          res?.data?.["error_code"] === 5 ||
          res?.data?.["error_code"] === 6 ||
          res?.data?.["error_code"] === 8
          )
      ) {
        setInputValues(initialOtpValues);
        setLoading(false);
        toast.error(res?.data?.message);
      }
      return res.data ?? "";
    } catch (err) {
      // toast.error("Something went wrong while submitting your request!");
      console.log("Something went wrong!", err);
    }
  };

  const resendMobileVerification = async () => {
    const values = {
      ip_address: await getIpAddress(),
      form_lat: state.latitude !== '' ? state.latitude : "0.0",
      form_lon: state.longitude !== '' ? state.longitude : "0.0",
      hash_key: localStorage.getItem("hash_key"),
    };

    try {
      const res = await AuthApiServices.resendMobileVerification(values);
      if (res?.data?.status === 1) {
        Toast.success(res?.data?.message);
        localStorage.setItem('hash_key', res?.data.hash_key);
        localStorage.setItem('ownership_uid', res?.data.ownership_uid);
        localStorage.setItem('expiry', res?.data.expiry_time);
        localStorage.setItem('session_key', res?.data.session_key);
        const expiryTime = getExpiryTime();
        setDuration(moment.duration(expiryTime, 'millisecond'));
      } else {
        Toast.error(res?.data?.message);
      }

      return res.data ?? "";
    } catch (err) {
      // toast.error("Something went wrong while submitting your request!");
      console.log("Something went wrong!", err);
    }
  };

  return (
    <div className="mobile-verification">
      <div className="background-layer-mobile background-blur-layer"></div>
      <div className="background-image-container-mobile">
        <img src="https://public.ticca.com/images/jpg/Back_Drop.jpg" alt=" "/>
      </div>

      <div className="mobile-header-container">
      <div className="logo-container">
          <Link to={clientRoutes.login} className="logo-link-container">
            <span className="logo-icon">
              {/* <TiccaLogoIconWhite /> */}
            </span>
            <span className="logo-title">
              <TiccaLogoTextWhite />
            </span>
          </Link>
        </div>
      </div>
      <div className="two-factor-text">Verify</div>
      <div className="text-factor">
        Enter your verification code sent to <br />
        {phone}
      </div>
      <div className="otp">
        <OtpStack
          duration={duration}
          error={error}
          inputValues={inputValues}
          setInputValues={setInputValues}
          setDuration={setDuration}
          fieldIntIndex={""}
        />
      </div>
      {/* <div className="time-verify">
        Your verification code will be valid for 0:59
      </div> */}
      {/* <Button
          variant="outlined"
          className={`add-button font-montserrat-500 ${
            state.isSubmitting ? "button-disabled" : ""
          }`}
          type="submit"
        >
          {state.isSubmitting ? (
            <div className="loader-container">
              <CircularProgress size="20px" />
            </div>
          ) : null}
          <span>Create Task</span>
        </Button> */}

      <div className="button-verify">
        <button
          className={`submit-button font-montserrat-500 ${
            loading ? "button-disabled" : ""
          }`}
          type="submit"
          onClick={handleSubmitOTP}
          disabled={duration <= 0}
        >
          {loading ? (
            <div className="loader-container">
              <CircularProgress size="20px" />
            </div>
          ) : null}
          <span className="login-text font-montserrat-500">Submit</span>
        </button>
      </div>
      <div className="resend-text">
        <div>Haven’t received verification code?{""}</div>
        <span onClick={resendMobileVerification}>
          <Link>RESEND</Link>
        </span>
      </div>
      {/* <p className="this-code">
        <div>How do I get this code?</div>
      </p> */}
      <p className="back-login">
        <Link to={clientRoutes.login}>Back to login page</Link>
      </p>
    </div>
  );
};

export default MobileVerification;
