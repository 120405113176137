export class clientRoutes {
  static login = "/";
  static dashboard = "/dashboard";
  static logout = "/logout";

  // Widgets
  static mobileVerification = "mobile_verification"
  static newDeviceMobileVerification = "new_device_verification"

  // static forgotPassword = "forgot-password";
  // static signup = "/signup";
  static remember = "remember";
  //
  // // Widgets
  // static timetrack = "timetrack";
  // static createTask = "tasks/create";
  // static createProject = "project/create";
  // static projectlist = "projectlist";
  // static tasklist = "task/list";
  // static projectDetail = "project/detail";
  // static taskDetail = "task/detail";
  // static about = "about"
  // static terms = "terms"
  // static finance = "finance"
  // static apps = "apps"
  // static report = "report"
  // static settings = "settings"
  // static profile = "profile"

}

// export const
