import React from 'react';

export const UserIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.905"
      height="18.846"
      viewBox="0 0 17.905 18.846"
      className='user-icon'
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_16"
            data-name="Rectangle 16"
            width="17.905"
            height="18.846"
            fill="#7f8596"
          />
        </clipPath>
      </defs>
      <g id="user_icon" clipPath="url(#clip-path)">
        <path
          id="Path_418"
          data-name="Path 418"
          d="M53.3,276.377a.13.13,0,0,0,.12-.184,6.876,6.876,0,0,0-3.087-3.283,11.507,11.507,0,0,0-10.771,0,6.851,6.851,0,0,0-3.087,3.283.13.13,0,0,0,.12.184ZM49.313,262.8a4.42,4.42,0,1,1-4.419-4.325A4.377,4.377,0,0,1,49.313,262.8Z"
          transform="translate(-36.004 -258.004)"
          fill="none"
          stroke="#7f8596"
          strokeLinecap="round"
          strokeWidth="0.955"
        />
      </g>
    </svg>
  );
};

export const EmailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.939"
      height="14.878"
      viewBox="0 0 17.939 14.878"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_20"
            data-name="Rectangle 20"
            width="17.939"
            height="14.878"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="email_icon" clipPath="url(#clip-path)">
        <path
          id="Path_422"
          data-name="Path 422"
          d="M36.486,378.648c-.056.743-.056,1.665-.056,2.819v2c0,2.836,0,4.254.833,5.132s2.178.884,4.862.884h5.7c2.684,0,4.029,0,4.862-.884s.833-2.3.833-5.132v-2c0-1.154,0-2.076-.056-2.819l-7.535,4.417a1.878,1.878,0,0,1-1.913,0Z"
          transform="translate(-36.003 -375.03)"
          fill="none"
          stroke="#7f8596"
          strokeWidth="0.851"
        />
        <path
          id="Path_423"
          data-name="Path 423"
          d="M37.1,376.506l7.884,4.625,7.884-4.625a1.857,1.857,0,0,0-.169-.2c-.838-.878-2.178-.878-4.868-.878H42.136c-2.684,0-4.029,0-4.862.883A1.453,1.453,0,0,0,37.1,376.506Z"
          transform="translate(-36.009 -375.003)"
          fill="none"
          stroke="#7f8596"
          strokeWidth="0.851"
        />
      </g>
    </svg>
  );
};

export const PasswordIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.332"
      height="18.846"
      viewBox="0 0 17.332 18.846"
      className='password-icon'
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_22"
            data-name="Rectangle 22"
            width="17.332"
            height="18.846"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="password_icon">
        <path
          id="Path_428"
          data-name="Path 428"
          d="M36.563,438.226c0-1.936,0-2.9.6-3.505s1.568-.6,3.5-.6h8.216c1.936,0,2.9,0,3.505.6s.6,1.569.6,3.505v2.051c0,2.9,0,4.355-.9,5.26s-2.353.906-5.26.906H42.722c-2.9,0-4.356,0-5.26-.906s-.9-2.353-.9-5.26v-2.051Z"
          transform="translate(-36.107 -428.05)"
          fill="none"
          stroke="#7f8596"
          strokeWidth="0.912"
        />
        <path
          id="Path_429"
          data-name="Path 429"
          d="M48.921,433.59v-1.026a4.108,4.108,0,1,0-8.216,0v1.026"
          transform="translate(-36.141 -428.004)"
          fill="none"
          stroke="#7f8596"
          strokeLinecap="round"
          strokeWidth="0.912"
        />
        <path
          id="Path_430"
          data-name="Path 430"
          d="M44.83,442.307a2.051,2.051,0,1,0-2.051-2.051A2.051,2.051,0,0,0,44.83,442.307Z"
          transform="translate(-36.158 -428.083)"
          fill="#7f8596"
        />
      </g>
    </svg>
  );
};

export const NotSelectedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.98"
      height="17.98"
      viewBox="0 0 17.98 17.98"
    >
      <g
        id="Rectangle_37"
        data-name="Rectangle 37"
        fill="none"
        stroke="#c9ccd3"
        strokeWidth="1"
      >
        <rect width="17.98" height="17.98" rx="8.99" stroke="none" />
        <rect
          x="0.5"
          y="0.5"
          width="16.98"
          height="16.98"
          rx="8.49"
          fill="none"
        />
      </g>
    </svg>
  );
};

export const CloseEyeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.475"
      height="16.72"
      viewBox="0 0 18.475 16.72"
      className='close-eye-icon'
    >
      <g id="close_eye_icon" transform="translate(0 0.354)">
        <path
          id="Path_424"
          data-name="Path 424"
          d="M311.856,438.76a3.97,3.97,0,0,0-4.681-4.681l.916.916a2.975,2.975,0,0,1,2.85,2.85Zm-6.474-2.266a2.977,2.977,0,0,0,4.06,4.06l.72.72a3.968,3.968,0,0,1-5.5-5.5Z"
          transform="translate(-298.773 -428.049)"
          fill="#7f8596"
          fillRule="evenodd"
        />
        <path
          id="Path_425"
          data-name="Path 425"
          d="M312.045,443.179l-.728-.728a7.133,7.133,0,0,1-3.391.951,8.044,8.044,0,0,1-4.6-1.712,17.388,17.388,0,0,1-3.143-3c-.422-.511-.465-.6-.465-.742s.044-.231.465-.742a17.6,17.6,0,0,1,2.992-2.891l-.708-.708a18.712,18.712,0,0,0-3.05,2.969l-.059.071a1.655,1.655,0,0,0,0,2.6l.059.071a18.355,18.355,0,0,0,3.33,3.175,9.018,9.018,0,0,0,5.175,1.9A8.265,8.265,0,0,0,312.045,443.179Zm-6.906-11.115a7.508,7.508,0,0,1,2.787-.564,9.019,9.019,0,0,1,5.175,1.9,18.353,18.353,0,0,1,3.33,3.175l.059.071a1.656,1.656,0,0,1,0,2.6l-.059.071a19.941,19.941,0,0,1-1.967,2.069l-.7-.7a18.884,18.884,0,0,0,1.9-2c.421-.511.464-.6.464-.742s-.044-.231-.464-.742a17.389,17.389,0,0,0-3.143-3,8.045,8.045,0,0,0-4.6-1.712,6.326,6.326,0,0,0-2.015.345Z"
          transform="translate(-298.73 -428.028)"
          fill="#7f8596"
          fillRule="evenodd"
        />
        <path
          id="Path_426"
          data-name="Path 426"
          d="M301,428l15.87,15.87"
          transform="translate(-298.748 -428)"
          fill="none"
          stroke="#7f8596"
          strokeWidth="1"
          className='close-eye-line'
        />
      </g>
    </svg>
  );
};

export const OpenEyeIcon = () => {
  return (
    <svg
      id="open_eye_icon"
      xmlns="http://www.w3.org/2000/svg"
      width="18.496"
      height="12.904"
      viewBox="0 0 18.496 12.904"
      className='open-eye-icon'
    >
      <path
        id="Path_644"
        data-name="Path 644"
        d="M19.585,11.877a10.271,10.271,0,0,0-9.212-6.252,10.271,10.271,0,0,0-9.211,6.252.56.56,0,0,0,0,.4,10.27,10.27,0,0,0,9.211,6.253,10.871,10.871,0,0,0,9.212-6.252.56.56,0,0,0,0-.4Zm-9.212,5.479c-3.267,0-6.258-2.338-7.972-5.279C3.892,9.386,7.179,6.735,10.374,6.8a9.746,9.746,0,0,1,7.972,5.279C16.8,15.046,13.642,17.356,10.374,17.356Z"
        transform="translate(-1.126 -5.625)"
        fill="#7f8596"
      />
      <path
        id="Path_645"
        data-name="Path 645"
        d="M14.769,11.25a3.519,3.519,0,1,0,3.519,3.519A3.519,3.519,0,0,0,14.769,11.25Zm0,5.865a2.346,2.346,0,1,1,2.346-2.346A2.346,2.346,0,0,1,14.769,17.115Z"
        transform="translate(-5.886 -8.317)"
        fill="#7f8596"
      />
    </svg>
  );
};

export const CircleCheckedFilledIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
    </svg>
  );
};

export const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#FFFFFF"
      className='close-icon'
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
    </svg>
  );
};
