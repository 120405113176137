import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  countdownText: {
    fontWeight: [100, '!important'],
    color: 'white',
    textAlign: 'center',
    fontSize: ['14px', '!important']
  },
}));
