import axios from "axios";

let env = process.env.REACT_APP_STAGE_ENV || "";
let apiEndpoint = process.env.REACT_APP_TICCA_API_ENDPOINT;
console.log("process.env >>>", process.env);

if (env === "dev") {
  apiEndpoint = process.env.REACT_APP_TICCA_API_DEV_ENDPOINT;
}

if (env === "stage") {
  apiEndpoint = process.env.REACT_APP_TICCA_API_STAGE_ENDPOINT;
}

console.log("STAGE_ENV >>>", env);
console.log("apiEndpoint >>>", apiEndpoint);

export const axiosAuthInstance = axios.create({
  baseURL: `${apiEndpoint}/auth`,
  headers: {
    "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary2042MHXUt7ofBGDs",
    Accept: "application/json",
    // "Accept-Language": "en",
    // "Content-Language": "en",
  },
  // baseURL: kaosApiUrl,
});

export const axiosBaseInstance = axios.create({
  baseURL: `${apiEndpoint}`,
  headers: {
    "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary2042MHXUt7ofBGDs",
  },
});

export const axiosKaosInstance = axios.create({
  baseURL: `${apiEndpoint}/kaos`,
  headers: {
    "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary2042MHXUt7ofBGDs",
    Accept: "application/json",
    "Accept-Language": "en",
    "Content-Language": "en",
  },
});


export const AxiosControlInstance = axios.create({
  baseURL: `${apiEndpoint}/control`,
  headers: {
    "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary2042MHXUt7ofBGDs",
    Accept: 'application/json',
    'Accept-Language': 'en',
    'Content-Language': 'en'
  }
});

axiosKaosInstance.interceptors.request.use(
  async function (config: any) {

    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
      config.headers["Content-Type"] = `multipart/form-data`;
    }
    if (
      config != null &&
      config.headers != null &&
      config.headers.common != null
    ) {
      return config;
    }
    return config
  }, function (error) {
    return Promise.reject(error);
  }
);

axiosBaseInstance.interceptors.request.use(
  async function (config: any) {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    if (
      config != null &&
      config.headers != null &&
      config.headers.common != null
    ) {
      return config;
    }
    return config
  }, function (error) {
    return Promise.reject(error);
  }
); 