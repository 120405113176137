import React from "react";

interface IWarningRoundIcon {
  id: string;
}

const WarningRoundIcon = ({ id }: IWarningRoundIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="89"
      height="89"
      viewBox="0 0 89 89"
      className="warning-round-icon"
    >
      <defs>
        <linearGradient
          id={`linear-gradient-${id}`}
          x1="0.5"
          x2="0.5"
          y2="1.514"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color="#ffb200" />
          <stop offset="1" stop-color="#654700" />
        </linearGradient>
      </defs>
      <g id="Group_2349" transform="translate(-1262 -395)">
        <circle
          id="Ellipse_84"
          cx="44.5"
          cy="44.5"
          r="44.5"
          transform="translate(1262 395)"
          fill={`url(#linear-gradient-${id})`}
        />
        <g id="warning" transform="translate(1287.818 415.969)">
          <path
            id="Path_2299"
            d="M16.875,12.375H19.49V26.759H16.875Z"
            transform="translate(1.432 0.701)"
            fill="#fff"
          />
          <path
            id="Path_2300"
            d="M16.875,27H19.49v2.615H16.875Z"
            transform="translate(1.432 3.075)"
            fill="#fff"
          />
          <path
            id="Path_2301"
            d="M22.212,1.125H19.268L1.125,36.2v4.154H40.353V36.2ZM37.738,37.738h-34v-.9l17-32.865,17,32.865Z"
            transform="translate(-1.125 -1.125)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

export default WarningRoundIcon;
