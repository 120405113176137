import React, { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import moment from "moment";
// import {useStyles} from "../../uti"
import useInterval from "use-interval";
// import { AbcRounded } from '@mui/icons-material';
import OtpInput from "./OtpInput";
import { useStyles } from "./styles";

function OtpStack({
  error,
  inputValues,
  setInputValues,
  duration,
  setDuration,
  fieldIntIndex,
}) {
  // const classes = useStyles();

  const [interval, setInterval] = useState(1000);
  const classes = useStyles();

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.target.id.includes("otp")) {
        const key = e.code;
        const tempValues = { ...inputValues };
        if (
          (key === "Backspace" || key === "Delete") &&
          e.target.value === ""
        ) {
          // e.target.value = '';
          let updatedIndex = Number(e.target.name) - 1;
          if (updatedIndex === 0) {
            updatedIndex = 1;
          }
          const previousInput = document.getElementById(`otp-${updatedIndex}`);
          const currentTarget = document.getElementById(e.target.id);
          previousInput.focus();
          currentTarget.blur();
          currentTarget.value = "";
          tempValues[e.target.id] = "";
          // setInputValues(tempValues);
        } else {
          // tempValues[fieldId] = e.key;
        }

        // setInputValues(tempValues);
      }
    });
  });

  const renderInputs = (max) => {
    let rows = [];
    for (let i = 1; i <= max; i++) {
      rows.push(
        <OtpInput
          inputValues={inputValues}
          setInputValues={setInputValues}
          key={`index_otp-${i}`}
          fieldId={`otp-${i}`}
          index={i}
        />
      );
    }
    return rows;
  };

  const handlePaste = (event) => {
    const clipboardText = event.clipboardData.getData('text/plain');
    let inputValues = {};
    for (let i = 0; i < 6; i++) {
      inputValues[`otp-${i + 1}`] = clipboardText.charAt(i);
    }
    setInputValues({ ...inputValues });
    const element = document.getElementById(`otp-6`);

    if (element !== null) {
      element.focus();
    }
  };

  useEffect(() => {
    window.addEventListener('paste', handlePaste);
    return () => {
      window.removeEventListener('paste', handlePaste);
    };
  }, []);

  // useInterval(() => {
  //   setDuration((previous) => moment.duration(previous.subtract(1, 'second'), 'seconds'));
  //   console.log('duration ', duration.as('seconds'));
  //   if (duration.as('milliseconds') <= 0) {
  //     setInterval(null);
  //   }
  // }, interval);

  const [isRunning, setIsRunning] = useState(true);

  useInterval(() => {
    setDuration((prevDuration) => {
      const newDuration = moment.duration(prevDuration.asMilliseconds() - 1000, 'milliseconds');
      if (newDuration.asMilliseconds() <= 0) {
        setIsRunning(false);
      }
      return newDuration;
    });
    // console.log('duration ', duration.as('seconds'));
  }, isRunning ? 1000 : null);

  

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
      >
        {renderInputs(6)}
      </Stack>
      {error && (
        <Box sx={{ marginLeft: "10px" }}>
          {Array.isArray(error.message) ? (
            error.message.map((el, index) => (
              <Typography
                gutterBottom
                sx={{ fontSize: 20, color: "#d32f2f", marginLeft: "10px" }}
                key={index}
              >
                {el}
              </Typography>
            ))
          ) : (
            <Typography
              gutterBottom
              sx={{
                fontSize: 20,
                color: "#d32f2f",
                marginLeft: "14px",
                paddingTop: "5px",
              }}
            >
              {error.message}
            </Typography>
          )}
        </Box>
      )}

      {(!error && !isRunning)  ? (
        <Box sx={{ marginTop: '12px' }}>
        <Typography className={classes.countdownText}>
          Your verification code has expired <br /> please click resend to get a new code
        </Typography>
        </Box>
      ):
      (
        <Box sx={{ marginTop: "12px", display: "flex", gap: "5px" }}>
        <Typography>Your verification code will be valid for</Typography>
        <Typography>
          {moment.utc(duration.as("milliseconds")).format("mm:ss")}
        </Typography>
      </Box>
      ) 
      }
    </Box>
  );
}

export default OtpStack;
