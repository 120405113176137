import { IVerificationRespose }from "../../@type/api/IAuthSerivices";
import { LocalStorageContants } from "../../helpers/constants/localStorageConstants";
import { clientRoutes } from "../../utils/routes";
import { Toast } from "../../utils/toast";

export class AuthLocalStorageServices {
  static tokens = {
    setToken: (data: IVerificationRespose) => {
      try {
        // if (data["hash-key"]) {
        //   localStorage.setItem(
        //     LocalStorageContants.auth.hashKey,
        //     data["hash-key"] ?? ""
        //   );

        //   localStorage.setItem(
        //     LocalStorageContants.auth.last_login,
        //     data?.["last-login"] ?? ""
        //   );
        //   return;
        // }
        localStorage.setItem(
          LocalStorageContants.auth.accessToken,
          data?.access_token ?? ""
        );

        // Toast.error("Authorization token is not found!");
        // console.log("Authorization token is not found!", data);
      } catch (err) {
        console.log(
          "Something went wrong while storing user information to storage",
          err
        );
        Toast.error(
          "Something went wrong while storing user information to storage"
        );
      }
    },

    getToken: (): string => {
      try {
        return localStorage.getItem(LocalStorageContants.auth.hashKey) ?? "";
      } catch (err) {
        console.log(
          "Something went wrong while getting user authentication details from localstorage!",
          err
        );
        Toast.error(
          "Something went wrong while getting user authentication details from localstorage!"
        );
      }
      return "";
    },

    getAccessToken: (): string | null => {
      try {
        const subTokenString = localStorage.getItem(
          LocalStorageContants.auth.accessToken
        ) as string;

        if (subTokenString) {
          return subTokenString;
        } else {
          if (window.location.pathname === clientRoutes.login) {
            return null;
          }
          // window.location.replace(clientRoutes.login);
        }
        return null;
      } catch (err) {
        console.log(
          "Something went wrong getting information from localstorage!",
          err
        );
        Toast.error(
          "Something went wrong getting information from localstorage!"
        );
        if (window.location.pathname === clientRoutes.login) {
          return null;
        }
        // window.location.replace(clientRoutes.login);
        return null;
      }
    },

    setCrossSiteToken: (data: IVerificationRespose) => {
      try {
        localStorage.setItem(
          LocalStorageContants.auth.crossSiteToken,
          data?.access_token ?? ""
        );
      } catch (err) {
        console.log(
          "Something went wrong while storing user information to storage",
          err
        );
        Toast.error(
          "Something went wrong while storing user information to storage"
        );
      }
    },

    getCrossSiteToken: (): string | null => {
      try {
        return localStorage.getItem(LocalStorageContants.auth.crossSiteToken) ?? "";
      } catch (err) {
        console.log(
          "Something went wrong getting information from localstorage!",
          err
        );
        Toast.error(
          "Something went wrong getting information from localstorage!"
        );
        return null;
      }
    },

    setBadUuid: (badUuid: string) => {
      try {
        return localStorage.setItem(LocalStorageContants.auth.badUid, badUuid);
      } catch (err) {
        console.log(
          "Something went wrong while getting user authentication details from localstorage!",
          err
        );
        Toast.error(
          "Something went wrong while getting user authentication details from localstorage!"
        );
      }
    },

    getBadUuid: (): string => {
      try {
        return localStorage.getItem(LocalStorageContants.auth.badUid) ?? "";
      } catch (err) {
        console.log(
          "Something went wrong while getting user authentication details from localstorage!",
          err
        );
        Toast.error(
          "Something went wrong while getting user authentication details from localstorage!"
        );
        // if (window.location.pathname === clientRoutes.login) {
        //   return "";
        // }
        // window.location.replace(clientRoutes.login);
      }
      return "";
    },

    // setAllData: (data: IVerificationRespose) => {
    //   try {
    //     // if (data["hash-key"]) {
    //     //   localStorage.setItem(
    //     //     LocalStorageContants.auth.hashKey,
    //     //     data["hash-key"] ?? ""
    //     //   );
    //     // }
    //     // if (data["contact-uid"]) {
    //     //   localStorage.setItem(
    //     //     LocalStorageContants.auth.contactUid,
    //     //     data["contact-uid"] ?? ""
    //     //   );
    //     // }
    //     // if (data["bad-uuid"]) {
    //     //   localStorage.setItem(
    //     //     LocalStorageContants.auth.badUid,
    //     //     data["bad-uuid"] ?? ""
    //     //   );
    //     // }
    //     // if(data["last_login"]){
    //     //   localStorage.setItem(
    //     //     LocalStorageContants.auth.last_login,
    //     //     data["last_login"] ?? ""
    //     //   );
    //     // }
    //     Toast.error("Authorization token is not found!");
    //     console.log("Authorization token is not found!", data);
    //   } catch (err) {
    //     console.log(
    //       "Something went wrong while storing user information to storage",
    //       err
    //     );
    //     Toast.error(
    //       "Something went wrong while storing user information to storage"
    //     );
    //   }
    // },
  };
}
